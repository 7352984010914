var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalAddPackage",attrs:{"titulo":"Añadir paquete","tamano":"modal-lg","no-aceptar":"","no-cerrar":"","no-cancelar":"","btns":_vm.buttons},on:{"accion":_vm.accionBoton}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"pr-3 wM-785px",staticStyle:{"padding-left":"43px"}},[_c('ValidationObserver',{ref:"validatorDestino"},[_c('p',{staticClass:"f-17 fr-medium text-general"},[_vm._v("Paquete")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-8 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:45","name":"contenido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-15"},[_vm._v(" Contenido ")]),_c('el-input',{staticClass:"wf-505px br-12",attrs:{"size":"large","placeholder":"Descripción"},model:{value:(_vm.modelPaquete.contenido),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "contenido", $$v)},expression:"modelPaquete.contenido"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-auto my-2"},[_c('div',{staticClass:"wf-170px"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"unidad"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-3 text-general f-15 mb-2"},[_vm._v(" Unidad ")]),_c('el-radio-group',{staticClass:"radio-green d-flex",model:{value:(_vm.modelPaquete.unidad),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "unidad", $$v)},expression:"modelPaquete.unidad"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v(" Kg/cm ")]),_c('el-radio',{attrs:{"disabled":true,"label":0}},[_vm._v(" Lb/in ")])],1),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-6 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:10|numeric","name":"valor declarado"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Valor declarado ")]),_c('el-input',{staticClass:"wf-340px br-12",attrs:{"size":"large","placeholder":"Valor"},model:{value:(_vm.modelPaquete.valor_declarado),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "valor_declarado", $$v)},expression:"modelPaquete.valor_declarado"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-6 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:10|numeric","name":"cantidad paquetes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Cantidad de paquetes ")]),_c('el-input',{staticClass:"wf-340px br-12",attrs:{"size":"large","placeholder":"Número"},model:{value:(_vm.modelPaquete.cantidad_paquetes),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "cantidad_paquetes", $$v)},expression:"modelPaquete.cantidad_paquetes"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-lg-3 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:4|numeric","name":"largo"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Largo (cm) ")]),_c('el-input',{staticClass:"wf-155px br-12",attrs:{"size":"large","placeholder":"Número"},on:{"input":_vm.calcularPesoVolumetrico},model:{value:(_vm.modelPaquete.largo),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "largo", $$v)},expression:"modelPaquete.largo"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:4|numeric","name":"ancho"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Ancho (cm) ")]),_c('el-input',{staticClass:"wf-155px br-12",attrs:{"size":"large","placeholder":"Número"},on:{"input":_vm.calcularPesoVolumetrico},model:{value:(_vm.modelPaquete.ancho),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "ancho", $$v)},expression:"modelPaquete.ancho"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:4|numeric","name":"alto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Altura (cm) ")]),_c('el-input',{staticClass:"wf-155px br-12",attrs:{"size":"large","placeholder":"Número"},on:{"input":_vm.calcularPesoVolumetrico},model:{value:(_vm.modelPaquete.alto),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "alto", $$v)},expression:"modelPaquete.alto"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('div',{staticClass:"col-12 col-lg-3 my-2"},[_c('ValidationProvider',{attrs:{"rules":"required|max:4|numeric","name":"peso"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('p',{staticClass:"pl-2 text-general f-15"},[_vm._v(" Peso (kg) ")]),_c('el-input',{staticClass:"wf-155px br-12",attrs:{"size":"large","placeholder":"Número"},model:{value:(_vm.modelPaquete.peso),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "peso", $$v)},expression:"modelPaquete.peso"}}),_c('p',{staticClass:"text-danger w-100 f-11"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('p',{staticClass:"col-12 col-lg-6 wf-370px my-2 f-15 text-general"},[_vm._v("Peso volumétrico: "),_c('span',{staticClass:"fr-medium"},[_vm._v(_vm._s(_vm.pesoVolumetrico)+" Kg")])]),_c('p',{staticClass:"col-12 col-lg-6 wf-370px my-2 f-15 text-general text-right text-danger text-decoration-underline cr-pointer",on:{"click":_vm.redirectReastricciones}},[_vm._v("Prohibiciones y restricciones de envío ")])]),_c('div',{staticClass:"row my-2"},[_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"d-middle h-40px"},[_c('p',{staticClass:"f-15 text-general"},[_vm._v("Servicios adicionales")]),_c('el-checkbox',{staticClass:"mx-3",on:{"change":_vm.limpiarImputSeguro},model:{value:(_vm.checked),callback:function ($$v) {_vm.checked=$$v},expression:"checked"}},[_vm._v("Seguro")]),(_vm.checked)?_c('el-input',{staticClass:"wf-230px br-12",attrs:{"size":"large","placeholder":"Valor"},model:{value:(_vm.modelPaquete.seguro),callback:function ($$v) {_vm.$set(_vm.modelPaquete, "seguro", $$v)},expression:"modelPaquete.seguro"}}):_vm._e()],1)])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }