import axios from 'axios'

const API = 'paqueteria'

const Paqueteria = {
    listarPaises(){
        return axios.get(`${API}/paises`)
    },
    listarEstados(params){
        return axios.get(`${API}/estados`, {params})
    },
    listarTransportadoras(params){
        return axios.get(`${API}/transportadoras`, {params})
    },
    listarInformacionInicial(idAdminPedido){
        return axios.get(`${API}/informacion-inicial/${idAdminPedido}`)
    },
    cotizarEnvio(payload){
        return axios.post(`${API}/cotizar`, payload)
    },
    informacionCodigoPostal(params){
        return axios.get(`${API}/geocercas`, {params})
    },
    generarGuia(payload){
        return axios.post(`${API}/generar-guia`, payload)
    },
}

export default Paqueteria
