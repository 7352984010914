<template>
  	<div class="card-destination d-flex px-2">
		<div class="wh-24 bg-gris br-4 d-middle-center">
			<i class="icon-package-variant f-18" />
		</div>
		<div class="ml-2 flex-fill">
			<p class="f-14 text-general fr-semibold tres-puntos">{{ item.contenido }}</p>
			<p class="f-12 text-grey2"> {{ separadorNumero(parseInt(item.valor_declarado)) }} </p>
			<p class="f-12 text-grey2 fr-light"> {{ item.alto+' * '+item.ancho+' * '+item.largo+' cm' }} ({{ item.peso+' kg' }}) </p>
			<p class="f-14 fr-light text-general">Seguro: {{ item.seguro ? separadorNumero(parseInt(item.seguro)) : separadorNumero(0) }}</p>
		</div>
		<div class="d-middle">
			<div class="d-middle-center h-22px wm-21px rounded-pill bg-general text-white f-14 fr-medium">
				<p>{{ item.cantidad_paquetes }}</p>
			</div>
			<i class="icon-delete-outline text-general f-23 cr-pointer" @click="$emit('openDelete')" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
        item: {
            type: Object,
            default: () => {
				return {
					nombre: '',
					valor: 0,
					medidas: '',
					peso: '()',
					seguro: 0,
					seguroValor: 0,
					cantPaquetes: 0,
				}
			}
        }
    },
	methods: {
		openDeletePackage(){
			// this.$refs.refModalCotizarEnvioTodos.toggle()
		},
	}
}
</script>

<style>

</style>