<template>
    <modal ref="modalAddPackage" titulo="Añadir paquete" tamano="modal-lg" no-aceptar no-cerrar no-cancelar :btns="buttons" @accion="accionBoton">
		<div class="row justify-content-center">
			<div class="col-12">
				<div class="pr-3 wM-785px" style="padding-left: 43px;">
					<ValidationObserver ref="validatorDestino">
					<!--  -->
						<p class="f-17 fr-medium text-general">Paquete</p>
						<div class="row">
							<div class="col-12 col-lg-8 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:45" name="contenido">
									<p class="pl-3 text-general f-15"> Contenido </p>
									<el-input v-model="modelPaquete.contenido" size="large" placeholder="Descripción" class="wf-505px br-12" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<div class="col-12 col-lg-auto my-2">
								<div class="wf-170px">
									<ValidationProvider v-slot="{errors}" rules="required" name="unidad">
										<p class="pl-3 text-general f-15 mb-2"> Unidad </p>
										<el-radio-group v-model="modelPaquete.unidad" class="radio-green d-flex">
											<el-radio :label="1">
												Kg/cm
											</el-radio>
											<el-radio :disabled="true" :label="0">
												Lb/in
											</el-radio>
										</el-radio-group>
										<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
									</ValidationProvider>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-lg-6 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:10|numeric" name="valor declarado">
									<p class="pl-2 text-general f-15"> Valor declarado </p>
									<el-input v-model="modelPaquete.valor_declarado" size="large" class="wf-340px br-12" placeholder="Valor" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<div class="col-12 col-lg-6 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:10|numeric" name="cantidad paquetes">
									<p class="pl-2 text-general f-15"> Cantidad de paquetes </p>
									<el-input v-model="modelPaquete.cantidad_paquetes" size="large" class="wf-340px br-12" placeholder="Número" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
						</div>
						<div class="row">
							<div class="col-12 col-lg-3 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:4|numeric" name="largo">
									<p class="pl-2 text-general f-15"> Largo (cm) </p>
									<el-input v-model="modelPaquete.largo" size="large" class="wf-155px br-12" placeholder="Número" @input="calcularPesoVolumetrico" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<div class="col-12 col-lg-3 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:4|numeric" name="ancho">
									<p class="pl-2 text-general f-15"> Ancho (cm) </p>
									<el-input v-model="modelPaquete.ancho" size="large" class="wf-155px br-12" placeholder="Número" @input="calcularPesoVolumetrico" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<div class="col-12 col-lg-3 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:4|numeric" name="alto">
									<p class="pl-2 text-general f-15"> Altura (cm) </p>
									<el-input v-model="modelPaquete.alto" size="large" class="wf-155px br-12" placeholder="Número" @input="calcularPesoVolumetrico" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<div class="col-12 col-lg-3 my-2">
								<ValidationProvider v-slot="{errors}" rules="required|max:4|numeric" name="peso">
									<p class="pl-2 text-general f-15"> Peso (kg) </p>
									<el-input v-model="modelPaquete.peso" size="large" class="wf-155px br-12" placeholder="Número" />
									<p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
								</ValidationProvider>
							</div>
							<p class="col-12 col-lg-6 wf-370px my-2 f-15 text-general">Peso volumétrico: <span class="fr-medium">{{ pesoVolumetrico }} Kg</span> </p>
							<p class="col-12 col-lg-6 wf-370px my-2 f-15 text-general text-right text-danger text-decoration-underline cr-pointer" @click="redirectReastricciones">Prohibiciones y restricciones de envío </p>
						</div>
						<div class="row my-2">
							<div class="col-auto">
								<div class="d-middle h-40px">
									<p class="f-15 text-general">Servicios adicionales</p>
									<el-checkbox v-model="checked" class="mx-3" @change="limpiarImputSeguro">Seguro</el-checkbox>
									<!-- <ValidationProvider v-slot="{errors}" rules="max:8|numeric" name="seguro"> -->
										<el-input v-if="checked" v-model="modelPaquete.seguro" size="large" class="wf-230px br-12" placeholder="Valor" />
										<!-- <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
									</ValidationProvider> -->
								</div>
							</div>
						</div>
					</ValidationObserver>
				</div>
			</div>
		</div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
			pesoVolumetrico:0,
            modelPaquete: {
				contenido: '',
				unidad: '',
				valor_declarado: '',
				cantidad_paquetes: '',
				largo: '',
				ancho: '',
				alto: '',
				peso: '',
				seguro: null,
			},
			checked: false,
			value: '',
			buttons: [
                {texto: 'Cerrar', color: 'bg-whitesmoke4 text-grey2 btn mx-2 w-132px br-8 h-36px px-3 f-14', accion: 1},
                {texto: 'Guardar y agregar otro', color: 'bg-general text-white h-36px px-3 f-14', accion: 2},
                {texto: 'Guardar y regresar', color: 'bg-general text-white h-36px px-3 f-14', accion: 3}
            ]
        }
    },
    computed:{
    },
    methods: {
        toggle(){
            this.$refs.modalAddPackage.toggle()
        },
		accionBoton(key){
            switch (key){
				case 1:
					this.$refs.modalAddPackage.toggle()
					break;
				case 2:
					this.guardarAgregar()
					break;
				case 3:
					this.guardarSalir()
					break;
				default:
					break;
            }
        },
		redirectReastricciones(){
			window.open("https://envia.com/es-MX/legal/terminos-y-condiciones-especificos?n=terms_and_conditions_especifics", "_blank");
		},
		calcularPesoVolumetrico(){
			let alto = this.modelPaquete.alto
			let ancho = this.modelPaquete.ancho
			let largo = this.modelPaquete.largo
			let pesoVol = (alto * ancho * largo)/5000
			this.pesoVolumetrico = pesoVol > 0 ? pesoVol : 0
		},
		async guardarAgregar(){
			const valid = await this.$refs.validatorDestino.validate()
			if (!valid) return
			const payload = {...this.modelPaquete}
			this.$emit('agregarPaquete', payload)
			this.limpiarModal()

			// this.$refs.modalAddPackage.toggle()
		},
		async guardarSalir(){
			const valid = await this.$refs.validatorDestino.validate()
			if (!valid) return
			const payload = {...this.modelPaquete}
			this.$emit('agregarPaquete', payload)
			this.limpiarModal()
			this.$refs.modalAddPackage.toggle()
		},
		limpiarImputSeguro(){
			console.log("cnsdkjnvfdskjvn kj ncdzkocnok cfdsfcd");
			this.modelPaquete.seguro = null
		},
		limpiarModal(){
			this.modelPaquete.contenido = ''
			this.modelPaquete.unidad = ''
			this.modelPaquete.valor_declarado = ''
			this.modelPaquete.cantidad_paquetes = ''
			this.modelPaquete.largo = ''
			this.modelPaquete.ancho = ''
			this.modelPaquete.alto = ''
			this.modelPaquete.peso = ''
			this.modelPaquete.seguro = null
		}
    }
}
</script>
<style lang="scss" scoped>
.text-rojo{
	color: #FF1720
}
.vr{
	display: inline-block; align-self: stretch; width: 1px; min-height: 1em; background-color: currentcolor; opacity: .25;
}
.list-errors{
	.item:last-child{
		border-bottom: 1px solid transparent !important;
	}
}
</style>
